.App {
  font-family: 'DM Sans', sans-serif !important;
  /* height: 100vh; */

  margin: 0 auto;
  padding: 0;
  max-width: 700px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Question-title {
  color: burlywood;
  font-weight: bold;
}

.Question {
  color: #363b3f;
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
}

.Question-Normal {
  color: #363b3f;
  font-size: 20px;
  font-style: normal;
}
.Note {
  color: #363b3f;
  font-size: 16px;
  font-style: normal;
}
.select-option {
  color: #363b3f;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: bold !important;
  text-align: center;
}

.btn-action > span {
  color: rgb(255, 255, 255);
  font-size: x-large;
  font-weight: 600;
}

.tab-active {
  background-color: #f8ad40 !important;
}

.tab-deactive {
  box-shadow: none !important;
  background-color: #fcd6a0 !important;
}

.section-name {
  font-family: 'DM Sans', sans-serif !important;
  font-weight: bold !important;
  font-size: 15px !important;
  color: #f8ad40 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  flex-direction: column;
  /* height: 93vh; */
  display: flex;
  padding: 10px;
  width: 90%;
}

.wrapper .btn-holder {
  flex: none;
}

.wrapper .content {
  /* flex: 1; */
  width: 100%;
  padding-bottom: 50px;
}

/*  */

p.Basic-Paragraph {
  color: #000000;
  font-family: 'Minion Pro', serif;
  font-size: 12px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  orphans: 1;
  page-break-after: auto;
  page-break-before: auto;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  widows: 1;
}
p.ParaOverride-1 {
  margin-bottom: 65px;
  text-align: center;
}
p.ParaOverride-2 {
  -epub-hyphens: none;
  margin-bottom: 10px;
}
p.ParaOverride-3 {
  text-align: center;
}
span.CharOverride-1 {
  color: #f8ad40;
  font-family: 'DM Sans', sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: bold;
}
span.CharOverride-2 {
  color: #363b3f;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
}
span.CharOverride-3 {
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}
span.CharOverride-4 {
  color: #3dbbea;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

img._idGenObjectAttribute-1 {
  height: 25.22px;
  left: 0px;
  width: 25.2px;
}

._idContainerImage {
  text-align: right;
  padding-right: inherit;
}

.paperPadding {
  padding: 10px;
}

.ul_number_circle {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 4px;
  background: #fff;
  border: 2px solid #f8ad40;
  color: #f8ad40;
  text-align: center;
  font: 14px 'DM Sans', sans-serif;
  margin-left: 40px;
}
